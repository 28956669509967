import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageHeader from '../components/PageHeader'
import NextSteps from '../components/NextSteps'
import device from '../device'

const Partner = styled.div`
  padding: 20px 0;
  margin: 20px 0 60px;

  @media ${device.tablet} {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 60px 0 100px;
  }

  .image-wrapper {
    margin: 40px 40px 0 0;
    width: 100%;
    max-width: 400px;
    text-align: center;
  }

  p {
    max-width: 500px;
  }

  p:first-child {
    font-size: 24px;
    color: var(--blue-700);
  }

  @media ${device.tablet} {
    .image-wrapper {
      margin: 40px 40px 0 0;
    }

    :nth-child(even) {
      flex-direction: row-reverse;
  
      .image-wrapper {
        margin: 40px 0 0 40px;
      }
    }
  }
`

const PartnershipsPage = ({ data: { prismicPartnershipsPage } }) => {
  const { data } = prismicPartnershipsPage
  return (
    <Layout>
      <Helmet
        title={`Charthouse | ${data.title.text}`}
        meta={[
          { name: 'description', content: data.introduction.text},
          { property: 'og:title', content: `Charthouse | ${data.title.text}` },
          { property: 'og:description', content: data.introduction.text },
          { name: 'twitter:title', content: `Chartouse | ${data.title.text}` },
          { name: 'twitter:description', content: data.introduction.text },
        ]}
      />
      <PageHeader 
        heading={data.title.text}
        introduction={data.introduction.html}
      />

      <Container>
        {data.partners.map(({ partner_name, content, logo }) => (
          <Partner key={partner_name.text}>
            <div className="image-wrapper">
              <img src={logo.url} alt={partner_name} />
            </div>
            <div dangerouslySetInnerHTML={{ __html: content.html }} />
          </Partner>
        ))}
      </Container>

      {data.next_steps && data.next_steps.length > 0 && 
        <NextSteps
          steps={data.next_steps}
        />
      }
    </Layout>
  )
}

export default PartnershipsPage

export const pageQuery = graphql`
  query PartnershipsPageQuery {
    prismicPartnershipsPage {
      data {
        title {
          text
        }
        introduction {
          html
        }
        partners {
          partner_name {
            text
          }
          logo {
            url
          }
          content {
            html
          }
        }
        next_steps {
          heading {
            text
          }
          button_label {
            text
          }
          link {
            url
          }
        }
      }
    }
  }
`